import axios from "axios";
import { API_HOST } from "../config_servers";
import { getToken } from "./auth";

const api = axios.create({
    baseURL: API_HOST,
});

api.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    const usuario_id = localStorage.getItem("@treinadelta/usuario_id");

    if (usuario_id > 0) {
        config.headers.usuario_id = usuario_id;
    }
    return config;
});

export default api;
